import { useEffect, useState } from "react";
import { LocalEnvType, SetLocalEnvType } from "../types/EnvTypes";

const LocalEnvAddress = typeof process.env.REACT_APP_LOCAL_ENV === "string" ? process.env.REACT_APP_LOCAL_ENV : "LocalEnv";

export const LoadEnv : () => LocalEnvType = () => {
    const getStore = localStorage.getItem(LocalEnvAddress);
    return getStore ? JSON.parse(getStore) : {}
}

export const StoreEnv = (envName : keyof LocalEnvType, envValue : any) => {
    let LocalEnv : LocalEnvType = LoadEnv();
    LocalEnv[envName] = envValue;

    localStorage.setItem(LocalEnvAddress, JSON.stringify(LocalEnv));
}

export const DeleteEnv = (envName : keyof LocalEnvType) => {
    let LocalEnv : LocalEnvType = LoadEnv();

    if(LocalEnv[envName]){
        delete LocalEnv[envName];
    }

    localStorage.setItem(LocalEnvAddress, JSON.stringify(LocalEnv));
}

export const useLocalEnv : () => [LocalEnvType, SetLocalEnvType] = () => {
    const [value, setValue] = useState(() => {
        return LoadEnv();
    });
  
    useEffect(() => {
        localStorage.setItem(LocalEnvAddress, JSON.stringify(value));
    }, [value]);
  
    return [value, setValue];
}
