import { ActiveLink, Link } from "raviger";
import { useEffect, useRef, useState } from "react";
import { LocalEnvPropsType } from "../../types/EnvTypes";
import { ServiceType } from "../../types/ServiceTypes";
import { API } from "../../utils/ApiUtils";
import { Ripple } from "../../utils/Ripple";
import { logoutUser } from "../../utils/UserUtils";

export default function Header(props : LocalEnvPropsType){

    const buttonsDefault = [
        {
            type : "link",
            name : "Dashboard",
            icon : "gauge",
            href : "/dashboard"
        },
        {   
            type : "club",
            name : "My Services",
            icon : "cubes",
            buttons : []
        },
        {
            type : "separator"
        },
        {
            type : "link",
            name : <>Documentation &nbsp; <i className="far fa-arrow-up-right-from-square" /></>,
            icon : "files",
            href : "https://shivank-kacker.gitbook.io/vishnu-api/",
            target : "_blank"
        },
        {
            type : "separator"
        },
        /*{
            type : "link",
            name : "Settings",
            icon : "cog",
            href : "/settings"
        },*/
        {
            type : "button",
            name : "Log out",
            icon : "sign-out",
            action : (e : any) => {
                logoutUser(props.localEnv, props.setLocalEnv);
            }
        },
    ]

    const [services, setServices] = useState<ServiceType[] | null>(null);
    const [buttons, setButtons] = useState<any[]>(buttonsDefault);

    useEffect(()=>{
        getServices();
    }, [])

    useEffect(()=>{
        setButtons(buttons.map((button : any) => {
            if(button.name === "My Services"){
                return {
                    ...button,
                    buttons : services?.map((service : ServiceType) => {
                        return {
                            type : "link",
                            name : service.sname,
                            icon : "cube",
                            href : `/service/${service.sid}`
                        }
                    })
                }
            }else{
                return button;
            }
        }));
    } , [services])

    const getServices = async () => {
        const services = await API.services.list();
        setServices(services);
    }

    return (
        <header className="w-[250px] fixed inset-y-0 left-0 border-r border-r-gray-300">
            <div className="flex p-[20px] items-center">
                <div>
                    <img src="/images/roo.png" className="w-[35px]" alt="Roo Logo" />
                </div>
                <div className="font-manropebold text-xl ml-[15px]">
                    Vishnu API
                </div>
            </div>
            <div>
                {buttons.map((button, i)=><RenderButton button={button} key={i}/>)}
            </div>
        </header>
    )
}

const RenderButton = (props : {button : any}) => {

    const {button} = props;

    const openerRef = useRef<HTMLDivElement>(null);

    switch (button.type) {
        case "link":
            return (
                <ActiveLink 
                    href={button.href || ""} 
                    className="px-[20px] py-[10px] rounded-lg m-[10px] block transition hover:bg-gray-100 header-link relative overflow-hidden" 
                    activeClass="active-link text-accent"
                    target={button.target} 
                    onClick={Ripple}
                >
                    <i className={`far fa-${button.icon} mr-2`}/> {button.name}
                </ActiveLink>
            )
        case "club":
            return (
                <div className="m-[10px]">
                    <button className="px-[20px] py-[10px] rounded-lg w-full flex items-center transition hover:bg-gray-100" onClick={(e)=>{
                        Ripple(e);
                        openerRef.current?.classList.toggle("sidepanel-button-open");
                        e.currentTarget.getElementsByClassName("fa-chevron-right")[0].classList.toggle("rotate-90");
                    }}>
                        <i className={`far fa-${button.icon} mr-2`}/> {button.name} <i className="far fa-chevron-right ml-4 transition"/>
                    </button>
                    <div ref={openerRef} className="ml-2 border-l border-l-gray-300 sidepanel-button">
                        {button.buttons?.map((b : any, i : number)=><RenderButton button={b} key={i}/>)}
                    </div>
                    
                </div>
            )
        case "separator": 
            return <hr className="border-gray-300" />

        case "button":
            return (
                <div className="m-[10px]">
                    <button className="px-[20px] py-[10px] rounded-lg w-full flex items-center transition hover:bg-gray-100 header-link relative" onClick={(e)=>{
                        Ripple(e);
                        button.action(e);
                    }}>
                        <i className={`far fa-${button.icon} mr-2`}/> {button.name}
                    </button>
                </div>
                
            )
        default:
            return <></>
    }
}