import { usePath, useQueryParams, useRoutes } from "raviger";
import React, { ReactNode, useEffect } from "react";
import AppContainer from "../components/AppContainer";
import Error404 from "../components/errors/Error404";
import { LocalEnvType, SetLocalEnvType } from "../types/EnvTypes";
import RouterFallback from "./RouterFallback";

const Dashboard = React.lazy(()=>import("../components/dashboard/Dashboard"));
const Login = React.lazy(()=>import("../components/login/Login"));
const ServicePage = React.lazy(()=>import("../components/service/ServicePage"));

const fallback = <RouterFallback/>

function LazyLoad(props : {children : ReactNode}){
    return (
        <React.Suspense fallback={fallback}>
            {props.children}
        </React.Suspense>
    )
}

export default function AppRouter(props : {localEnv : LocalEnvType, setLocalEnv : SetLocalEnvType}) {

    const path = usePath();
    const [queryParams] = useQueryParams();
    const {notif=""} = queryParams;

    useEffect(()=>{
        if(notif && notif !== ""){
            //API.notifications.read(notif);
        }
    },[notif])

    const homeRoute = (
        <LazyLoad>
            <Dashboard localEnv={props.localEnv} setLocalEnv={props.setLocalEnv}/>
        </LazyLoad>
    );
    const routes = {
        '/' : () => homeRoute,
        '/dashboard' : () => homeRoute,

        '/login' : () => <LazyLoad>
            <Login {...props}/>
        </LazyLoad>,

        "/service/:sid" : ({sid} : {sid : string}) => <LazyLoad>
            <ServicePage {...props} service_id={sid}/>
        </LazyLoad>
    }    
    const routeResult = useRoutes(routes) || <Error404/>;

    return path === "/login" ? routeResult : (<AppContainer localEnv={props.localEnv} setLocalEnv={props.setLocalEnv}>{routeResult}</AppContainer>);
}