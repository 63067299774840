import { ReactNode, useEffect} from "react";
import { LocalEnvType, SetLocalEnvType } from "../types/EnvTypes";
import { Ripple } from "../utils/Ripple";
import Header from "./header/Header";

export default function AppContainer(props : {children : ReactNode, localEnv : LocalEnvType, setLocalEnv : SetLocalEnvType}){

    return (
        <div className="font-manrope">
            <Header localEnv={props.localEnv} setLocalEnv={props.setLocalEnv}/>
            <div className="ml-[250px] p-10">
                {props.children}
            </div>
        </div>
    )
}