import { ServiceFormType } from "../components/dashboard/Services";
import { EditKeyFormType } from "../components/service/ServicePage";
import { LoadEnv } from "./EnvUtils";

export const API_BASE_URL = process.env.REACT_APP_API_URL;
const IP_INFO_TOKEN = process.env.REACT_APP_IPINFOTOKEN;
export const CURRENT_URL = window.location.protocol + "//" + window.location.host;

type endpoint = `${string}`;

type methods = "POST" | "GET" | "PATCH" | "DELETE" | "PUT";

const request = async (endpoint : endpoint, method : methods = "GET", data : any = {}, isformdata? : boolean, isExternal? : boolean) => {

    let url = isExternal ? endpoint : (API_BASE_URL + endpoint) ;
    let payload : null | string = isformdata ? data : JSON.stringify(data);

    if(method === "GET"){
        const requestParams = data ? `?${Object.keys(data).map(key=>`${key}=${data[key]}`).join('&')}` : "";
        url += requestParams;
        payload = null;
    }

    const localToken = LoadEnv().auth?.token;

    const auth = localToken === null || typeof localToken === "undefined" ? "" : "Token " + localToken;

    const response = await fetch(url, {
        method : method,
        headers : {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization : auth
        },
        body : payload
    });
    try {
        const json = await response.clone().json();
        if(json && response.ok){
            return json;
        }else{
            throw json;
        }
    } catch (error) {
        if(response.ok){
            return {error};
        }else{
            throw {error};
        }
    }
}

export const API = {
    user : {
        getData : (conf : string) => request("auth/redirect", "GET", {conf}), 
        me : () => request("users/me"),
    },
    external : {
        getUserIp : () => request(`https://ipinfo.io`, "GET", {token : IP_INFO_TOKEN}, false, true),
        initiateLogin : (backend : string) => request(backend, "GET", {}, false, true)
    },
    services : {
        list : (sid? : string) => request("services/get", "GET", sid ? {sid} : {}),
        create : (data : ServiceFormType) => request("services/create", "POST", data),
        delete : (service_id : string) => request("services/delete", "GET", {service_id}),
        edit : (data : ServiceFormType, service_id : string) => request("services/edit", "POST", {...data, service_id}),
    },
    keys : {
        get : (kid : string) => request("keys/get", "GET", {kid}),
        create : (info : {service_id : string, key_name : string, key_backend : string}) => request("keys/create", "POST", info),
        delete : (key_id : string) => request("keys/delete", "GET", {key_id}),
        edit : (info : EditKeyFormType) => request("keys/edit", "POST", info)
    }
}