import { usePath } from 'raviger';
import { useEffect, useState } from 'react';
import AppRouter from './routers/AppRouter';
import { API } from './utils/ApiUtils';
import { useLocalEnv } from './utils/EnvUtils';
import { logoutUser } from './utils/UserUtils';

function App() {
  const [localEnv, setLocalEnv] = useLocalEnv();
  const path = usePath();

  const getUserDetails = async () => {
    if(localEnv.auth){
      /*
      try {
        const user = await API.user.me();
        setLocalEnv({
          ...localEnv,
          auth : {
            ...localEnv.auth,
            uid : user.external_id,
            email : user.email,
            username : user.username,
            image : user.profile_picture
          }
        })
      } catch (error) {
        console.log(error);
        //logoutUser(localEnv, setLocalEnv);
      }
      */
    }else{
      logoutUser(localEnv, setLocalEnv)
    }
  }

  useEffect(()=>{
    if(path !== "/login"){
      getUserDetails();
    }
    // eslint-disable-next-line
  }, [path])

  useEffect(()=>{
    
  }, [localEnv])

  return (
    <AppRouter localEnv={localEnv} setLocalEnv={setLocalEnv}/>
  );
}

export default App;
