export const Ripple = (e : any) => {

    let target = e.currentTarget as HTMLElement;

    let targetPosition = target.style.position;

    const allowedPositions = [
        "absolute",
        "fixed",
        "sticky"
    ];

    if(!allowedPositions.includes(targetPosition)){
        target.classList.add("relative");
        target.classList.add("overflow-hidden");
    }

    target.classList.add("relative");
    target.classList.add("overflow-hidden");
    
    const xPos = e.pageX;
    const yPos = e.pageY;
    
    let aW = target.clientWidth * 4;
    const aH = target.clientHeight * 4;

    if(aW < aH){
        aW = aH;
    }

    const x = xPos - target.offsetLeft - (aW/2);
    const y = yPos - target.offsetTop - (aW/2);

    const rippleID = 'ripple_'+Math.random().toString(16).slice(2);

    const rippleDiv = document.createElement("div");
    rippleDiv.setAttribute("id", rippleID);
    rippleDiv.setAttribute("class", "ripple-circle");
    rippleDiv.setAttribute("style", `left:${x}px;top:${y}px;--aW:${aW}px;`);
    
    target.appendChild(rippleDiv);
    
    setTimeout(()=>{
        rippleDiv.remove();
        //target.classList.remove("relative");
        //target.classList.remove("overflow-hidden");
    }, 1000);

}